<template>
    <Loading v-if="processing" />
    <div v-else class="lg:relative">
        <div class="lg:m-2 md:m-6 mx-3 mt-3">
            <div class="flex justify-between">
                <div :class="{
                    'md:text-md text-sm top-rappers-text': true,
                    'text-black': currentRouteName != 'TopRappersWithState',
                    'text-white': currentRouteName == 'TopRappersWithState',
                }">
                    Top artists
                    {{
                        currentRouteName == "TopRappersWithState"
                        ? " in " + states[0].name
                        : ""
                    }}:
                    <span class="text-white">
                        <select name="week" class="p-1 text-base placeholder-white rapbg border-rounded focus:outline-none"
                            @change="topArtist()" v-model="RunningWeek">
                            <option v-for="selectweek in week" :key="selectweek" :value="selectweek.week">
                                {{ selectweek.start_date }} to {{ selectweek.end_date }}
                            </option>
                        </select>
                    </span>
                </div>
                <!-- <div class="flex">
                        <img src="../../assets/image/ellipsis.svg" alt="" class=" lg:w-8 md:w-12 md:h-12 w-8 h-6 justify-self-end">
                    </div> -->
            </div>
            <router-link class="grid grid-cols-2 mt-3 gap-3 users items-center" v-for="(artist, index) in topArtists"
                :key="index" :to="{ name: 'ProfileWithSlug', params: { slug: artist.slug } }">
                <div class="items-center">
                    <img src="../../assets/image/default.png" alt=""
                        class="rounded-full responsive-image profile-image-list float-left"
                        v-if="artist.image == null || artist.image == ''" />
                    <img onerror="this.onerror=null;this.src=document.getElementById('defaultImage').src;"
                        :src="media_base_url + artist.image" alt=""
                        class="rounded-full responsive-image profile-image-list float-left" v-else />
                    <p class="pl-2 artist-list-name text-sm truncate mt-1">
                        {{ index + 1 }}. {{ artist.member_name }}
                    </p>
                </div>
                <div :class="{
                    'text-right w-full text-sm': true,
                    'text-white': currentRouteName == 'TopRappersWithState',
                }">
                    <p>
                        {{ artist.vote_count }} Vote{{ artist.vote_count !== 1 ? "s" : "" }}
                    </p>
                </div>
            </router-link>
            <div class="text-right my-4" v-if="currentRouteName != 'TopRappersWithState'">
                <router-link class="text-sm top-rappers-text text-white cursor-pointer" :to="{ name: 'Artists' }"
                    v-if="artistCount > 0" @click="display">
                    View all
                </router-link>
            </div>
            <!-- <hr class="border-gray-300 mt-4"> -->
            <div class="border-b-2 border-black m-1" v-if="currentRouteName != 'TopRappersWithState'"></div>
            <div class="pb-2" v-if="currentRouteName != 'TopRappersWithState'">
                <!-- <div class="text-center my-8">
              <form @submit.prevent="search">
                <input type="text" name="" id="" placeholder="SEARCH FOR ARTISTS..."
                  class="placeholder-black font-bold text-black xl:w-10/12 md:w-7/12 w-9/12 text-md p-2 md:p-4 text-center bg-gray-100 rounded-xl focus:outline-none"
                  v-model="searchStr" />
              </form>
            </div> -->

                <div class="relative my-8">
                    <form @submit.prevent="search">
                        <div class="text-center">
                            <input type="text" @keyup="showOptions = true" v-model="searchStr"
                                class="placeholder-black font-bold w-full text-black text-md p-2 md:p-4 text-center bg-gray-100 rounded-xl focus:outline-none"
                                autocomplete="off" placeholder="Search for artists..." />
                        </div>
                    </form>
                    <div v-show="resultQuery.length && showOptions"
                        class="absolute max-h-80 w-full z-50 bg-white border border-gray-300 mt-1 max-height-48 overflow-hidden overflow-y-scroll rounded-md shadow-md">
                        <ul class="py-1">
                            <template v-for="(value, index) in resultQuery">
                                <li v-if="value.public_id" @click="setInput(value.slug)" :key="index"
                                    class="px-3 py-2 cursor-pointer hover:bg-gray-200">
                                    {{ value.member_name }}
                                </li>
                            </template>
                        </ul>
                    </div>
                </div>

                <div class="grid grid-cols-3 gap-3">
                    <div class="text-center" v-for="state in states" :key="state.id">
                        <router-link :to="{
                            name: 'TopRappersWithState',
                            params: { slug: state.slug },
                        }" v-if="state.status == 1"
                            class="flex justify-center items-center w-full h-full rounded-md px-1 py-1 break-word bg-black text-white py-2 truncate state-btn">
                            {{ state.name }}
                        </router-link>

                        <span v-else
                            class="flex justify-center items-center w-full h-full rounded-md px-1 py-1 break-word bg-gray-400 py-2 truncate state-btn">
                            {{ state.name }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import API from "../../api/http";
import Loading from "../Shared/Loading";
export default {
    name: "TopRappers",
    components: {
        Loading,
    },
    data() {
        return {
            media_base_url: process.env.VUE_APP_MEDIA_BASE_URL,
            show: false,
            // states: [],
            // topArtists: [],
            allArtists: [],
            viewmore: false,
            searchStr: "",
            getTopArtistUrl: "top-artists",
            loading: false,
            showOptions: false,
            RunningWeek: 0,
            data: [
                {
                    id: 1,
                    clients_name: "Jane Doe",
                },
                {
                    id: 2,
                    clients_name: "John Doe",
                },
                {
                    id: 3,
                    clients_name: "Hello World",
                },
            ],
            processing: true,
        };
    },
    watch: {
        $route() {
            // if(newValue.name == 'TopRappers'){
            //     }
            this.loading = true;
            this.topArtist();
        },
    },
    computed: {
        user() {
            return this.$store.getters["User/user"];
        },
        states() {
            return this.$store.getters["Toprappers/states"];
        },
        topArtists() {
            return this.$store.getters["Toprappers/topArtists"];
        },
        week() {
            return this.$store.getters["Toprappers/week"];
        },
        artistCount() {
            return this.$store.getters["Toprappers/artistCount"];
        },
        currentWeek() {
            return this.$store.getters["Toprappers/currentWeek"];
        },
        currentRouteName() {
            return this.$route.name;
        },
        resultQuery() {
            if (this.searchStr) {
                let data = this.allArtists.filter((item) => {
                    return this.searchStr
                        .toLowerCase()
                        .split(" ")
                        .every((v) => item.member_name.toLowerCase().includes(v));
                });

                return data;
            } else {
                return [];
            }
        },
    },
    created() {
        this.RunningWeek = this.currentWeek;
        document.addEventListener("DOMContentLoaded", function (event) {

            document.querySelectorAll("img").forEach(function (img) {
                img.onerror = function () {
                    console.log(event);
                    this.style.display = "none";
                };
            });
        });
        this.topArtist(this.getTopArtistUrl);
    },
    methods: {
        display() {
            this.show = !this.show;
            if (this.show == false) {
                // document.getElementById('view-all').innerHTML = 'VIEW ALL'
                this.topArtist(this.getTopArtistUrl);
            } else {
                //  document.getElementById('view-all').innerHTML = 'VIEW LESS';
                this.topArtist(this.getTopArtistUrl + "?filter=all");
            }
        },
        topArtist() {
            if (this.$route.name == "TopRappers") {
                API.get("top-artists" + "?week=" + this.RunningWeek).then(
                    (response) => {
                        this.loading = false;
                        this.allArtists = response.all_artists;

                        this.$store.commit("Toprappers/SET_STATES", response.states);
                        this.$store.commit("Toprappers/SET_ARTISTS", response.top_artists);
                        this.$store.commit(
                            "Toprappers/SET_WEEK",
                            response.current_year_weeks
                        );
                        if (this.currentWeek == 0) {
                            this.$store.commit(
                                "Toprappers/SET_CURRENT_WEEK",
                                response.current_year_weeks[
                                    response.current_year_weeks.length - 1
                                ].week
                            );
                            this.RunningWeek =
                                response.current_year_weeks[
                                    response.current_year_weeks.length - 1
                                ].week;
                        } else {
                            this.$store.commit(
                                "Toprappers/SET_CURRENT_WEEK",
                                this.RunningWeek
                            );
                        }

                        this.$store.commit(
                            "Toprappers/SET_ARTISTS_COUNT",
                            response.artist_count
                        );
                        this.processing = false;
                    }
                );
            } else {
                API.get("show-artist-state/" + this.$route.params.slug).then(
                    (response) => {
                        this.loading = false;
                        if (
                            response.top_artists &&
                            response.top_artists[0] &&
                            response.top_artists[0].user
                        ) {
                            this.topArtists = response.top_artists[0].user;
                        }
                        this.states = response.top_artists;
                        this.week = response.week;
                        this.currentWeek = response.current_year_weeks[0];
                        this.processing = false;

                        // this.$store.commit('Toprappers/SET_STATES', response.top_artists)
                        // this.$store.commit('Toprappers/SET_ARTISTS', response.top_artists[0].user)
                        // this.$store.commit('Toprappers/SET_WEEK', response.week)
                        // this.$store.commit('Toprappers/SET_ARTISTS_COUNT', response.artist_count)
                    }
                );
            }
        },
        search() {
            if (this.search != "") {
                this.$router.push({
                    name: "Search",
                    params: {
                        string: this.searchStr,
                    },
                });
            }
        },
        removeUser(e) {
            e, this.topArtist();
        },
        setInput(value) {
            // this.$set(this.searchStr, "client", value);
            // this.$set(this.searchStr, "client_id", value.id);
            if (this.search != "") {
                this.$router.push({
                    name: "ProfileWithSlug",
                    params: {
                        slug: value,
                    },
                });
            }
            this.searchStr = value;
            this.showOptions = false;
        },
    },
};
</script>
